<template>
  <base-index-layout :title="$t('inventory.name')" :show-button="false">
    <template #new-button>
      <!-- <base-new-button v-if="isAdmin" :to="{ path: 'new' }" append /> -->
    </template>
    <router-view />

    <InventoryItemIndexTableFilter @filter="filterItems" />
    <InventoryItemCSV />
    <InventoryItemIndexTable ref="table" />
  </base-index-layout>
</template>

<script>
import InventoryItemIndexTable from './components/InventoryItemIndexTable'
import InventoryItemIndexTableFilter from '@/views/inventory-item/components/InventoryItemIndexTableFilter'
import InventoryItemCSV from '@/views/inventory-item/components/InventoryItemCSV'

import { mapGetters } from 'vuex'
export default {
  components: {
    InventoryItemIndexTable,
    InventoryItemIndexTableFilter,
    InventoryItemCSV
  },
  computed: {
    ...mapGetters({
      meta: 'inventoryItem/meta',
      options: 'inventoryItem/options',
      isAdmin: 'auth/isAdmin'
    })
  },

  methods: {
    filterItems(filter) {
      let options = Object.assign({}, this.options)
      options = { ...options, ...filter }
      this.$store.dispatch('inventoryItem/setPagination', options)
      this.$refs.table.fetchData()
    }
  }
}
</script>

<template>
  <v-data-table
    id="inventory-items-table"
    :headers="headers"
    :items="inventoryItems"
    :loading="isLoading"
    item-key="id"
    loader-height="1"
    :page.sync="options.page"
    :server-items-length="meta.totalPages"
    hide-default-footer
    fixed-header
    @click:row="$emit('click:row', $event)"
  >
    <template v-slot:footer>
      <v-pagination
        v-model="options.page"
        light
        circle
        :length="meta.totalPages"
        total-visible="50"
        @input="onPagination"
      ></v-pagination>
    </template>
    <template v-slot:[`item.attributes.name`]="{ item }">
      <div>
        {{
          item.attributes.name
            ? $t(`inventory.categories.${item.attributes.name}`)
            : '-'
        }}

        {{
          item.attributes.customAttributes.partName &&
            ` [ ${$t(
              `inventory.categories.${item.attributes.customAttributes.partName}`
            )} ]`
        }}

        <v-icon
          v-if="item.attributes.category === 'end_product'"
          small
          color="success"
          >mdi-shopping</v-icon
        >
      </div>

      <div class="mt-1 font-weight-light">
        {{
          item.attributes.customAttributes.specie &&
            $t(`species.${item.attributes.customAttributes.specie.text}`)
              | upperCase
        }}

        {{
          item.attributes.customAttributes.category &&
            item.attributes.customAttributes.category
        }}
      </div>
      <div class="mt-1 font-weight-thin">
        {{
          item.attributes.customAttributes.type &&
            item.attributes.customAttributes.type
        }}
      </div>
    </template>

    <template v-slot:[`item.attributes.itemUnitsInStockCount`]="{ item }">
      {{ item.attributes.itemUnitsInStockCount }} ({{
        item.attributes.customAttributes.packQuantity || '1'
      }})
    </template>

    <template v-slot:[`item.attributes.description`]="{ item }">
      <div class="text-subtitle-2">{{ item.attributes.description }}</div>
    </template>
    <template v-slot:[`item.dimensions`]="{ item }">
      <DimensionsTableField :item="item" />
    </template>

    <template v-slot:[`item.quantity`]="{ item }">
      <div v-if="item.attributes.status === 'in_stock'">
        {{ item.attributes.quantity }}
      </div>
      <v-chip v-else small outlined color=" 'error'">{{
        item.attributes.status | titleCase
      }}</v-chip>
    </template>

    <!-- Link to Item Resource -->
    <template v-slot:[`item.log`]="{ item }">
      <v-btn
        v-if="item.attributes.customAttributes.resource"
        depressed
        x-small
        :to="{
          name: 'inventory-item-show',
          params: { inventoryItemId: item.attributes.customAttributes.resource }
        }"
        color="brown lighten-4"
        :disabled="!!item.attributes.clonedFromId"
        >{{ $t('actions.view') }}</v-btn
      >
    </template>
    <!-- Link to Incoming Shipment -->
    <template v-slot:[`item.shipment`]="{ item }">
      <v-btn
        v-if="item.attributes.customAttributes.incomingShipment"
        depressed
        x-small
        :to="{
          name: item.attributes.clonedFromId
            ? 'sale-purchase-order-show'
            : 'purchase-order-show',
          params: {
            orderId: item.attributes.clonedFromId
              ? item.attributes.orderId
              : item.attributes.customAttributes.incomingShipment
          }
        }"
        color="blue lighten-4"
        >{{ $t('actions.view') }}</v-btn
      >
    </template>
    <template v-slot:[`item.lot`]="{ item }">
      <!-- Link to ItemGroup -->
      <v-btn
        v-if="
          item.relationships.itemGroup.data ||
            item.attributes.customAttributes.itemGroupParentId
        "
        depressed
        x-small
        :to="{
          name: 'item-group-lot-show',
          params: {
            itemGroupLotId:
              (item.relationships.itemGroup.data &&
                item.relationships.itemGroup.data.id) ||
              item.attributes.customAttributes.itemGroupParentId
          }
        }"
        color="blue-grey lighten-4"
        >{{ $t('actions.view') }}</v-btn
      >
    </template>
    <!-- Actions -->
    <template v-slot:[`item.id`]="{ item }">
      <BaseTableMenu
        module-name="inventoryItem"
        :item="item"
        :show-router="{
          name: 'inventory-item-show',
          params: { inventoryItemId: item.id }
        }"
        :edit-router="{
          name: 'inventory-item-edit',
          params: {
            inventoryItemId: item.id
          }
        }"
        :create-router="{
          name: 'inventory-item-new',
          params: {
            parentId: item.id
          }
        }"
        qr
        @update="fetchData"
        @show-qr="$refs.dialog.createQrCode(item)"
      />
      <QrCodeGenerator ref="dialog" :url="qrUrl" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import DimensionsTableField from '@/components/DimensionsTableField'
import QrCodeGenerator from '@/views/qr-code/components/QrCodeGenerator'
export default {
  name: 'InventoryItemIndexTable',
  components: {
    DimensionsTableField,
    QrCodeGenerator
  },
  data() {
    return {
      selected: [],
      isLoading: true,
      inventoryItemGroups: [],
      headers: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      inventoryItems: 'inventoryItem/items',
      meta: 'inventoryItem/meta',
      facilities: 'facility/facilities',
      itemResources: 'itemResource/itemResources',
      options: 'inventoryItem/options',
      locale: 'i18n/locale'
    }),
    qrUrl: () => `${window.location.origin}/es/inventory/items/`
  },
  watch: {
    locale() {
      this.headers = this.getHeaders()
    }
  },

  created() {
    this.headers = this.getHeaders()
    this.fetchData()
  },

  methods: {
    async onPagination() {
      this.isLoading = true
      this.$store.dispatch('inventoryItem/setPagination', this.options)
      this.$vuetify.goTo('#inventory-items-table', 'easeInOutCubic')
      await this.filterInventoryItem()
      this.isLoading = false
    },
    async fetchData() {
      this.isLoading = true
      await this.filterInventoryItem()
      this.isLoading = false
    },
    async filterInventoryItem() {
      const {
        status,
        woodCategory,
        page,
        itemsPerPage,
        itemGroup,
        specie,

        name
      } = this.options
      const params = {
        organizationId: ORGANIZATION_ID(),
        'page[number]': page,
        'page[size]': itemsPerPage,
        sort: '-updated_at',
        status,
        woodCategory,
        itemGroup,
        specie,
        name
      }
      if (!this.isAdmin)
        Object.assign(params, { category: 'client_end_product' })
      await this.$store.dispatch('inventoryItem/filter', params)
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    async fetchItemResources() {
      return await this.$store.dispatch('itemResource/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    async fetchInventoryItemGroups() {
      return await this.$store.dispatch('inventoryItemGroup/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    getHeaders() {
      const headers = [
        {
          text: this.$t('item.description'),
          align: 'start',
          sortable: false,
          value: 'attributes.description'
        },
        {
          text: this.$tc('item.name', 1) + '#',
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: this.$t('inventory.in_stock') + ' /Pack',
          sortable: false,
          value: 'attributes.itemUnitsInStockCount',
          width: 70
        },
        {
          text: this.$t('item.dimensions'),
          sortable: false,
          value: 'dimensions'
        },
        {
          text: this.$t('inventory.categories.log'),
          sortable: false,
          value: 'log',
          width: 50
        },
        {
          text: this.$t('purchase.shortName'),
          sortable: false,
          value: 'shipment',
          width: 50
        },
        {
          text: this.$tc('lot.name', 1),
          sortable: false,
          value: 'lot',
          width: 70
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
      const userFilters = [
        'attributes.name',
        'attributes.description',
        'specie',
        'dimensions',
        'attributes.itemUnitsInStockCount',
        ''
      ]
      if (this.isAdmin) return headers
      return this.filterHeaders(headers, userFilters)
    },
    filterHeaders(headers, filters) {
      return headers.filter(header => filters.includes(header.value))
    }
  }
}
</script>

<template>
  <v-sheet>
    <v-container class="px-2">
      <div class="text-subtitle py-3">{{ $t('headers.exportCSV') }}</div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row dense>
          <v-col>
            <BaseSelector
              v-model="query.specie"
              dense
              outlined
              :label="$t('item.specie')"
              :items="species"
              :item-text="e => $t(`species.${e.text}`)"
              item-value="text"
              :placeholder="$t('species.Sapele')"
              :rules="rules"
              :loading="isLoading"
            />
          </v-col>
          <v-col>
            <BaseDatePicker
              v-model="query.startDate"
              dense
              :label="$t('common.from')"
              :rules="rules"
              :loading="isLoading"
            />
          </v-col>
          <v-col>
            <BaseDatePicker
              v-model="query.endDate"
              dense
              :label="$t('common.to')"
              :rules="rules"
              :loading="isLoading"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="secondary"
              depressed
              :loading="isLoading"
              class="text-capitalize"
              @click="onClick"
              ><v-icon left>mdi-file-excel-outline</v-icon>Export</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-sheet>
</template>

<script>
import { SPECIES } from '@/common/terms'
import { ORGANIZATION_ID } from '@/common/config'
import { mapActions } from 'vuex'
// import ApiService from '@/services/ApiService.js'
// import { ANALYTICS_PATH } from '@/common/config.js'

export default {
  data() {
    return {
      species: SPECIES,
      isLoading: false,
      query: this.createFreshQueryObject(),
      rules: [v => !!v || 'Campo Requerido'],
      valid: true
    }
  },
  methods: {
    ...mapActions({
      addNotification: 'notification/add',
      getCSV: 'dashboard/getCSV'
    }),
    async onClick() {
      if (!this.$refs.form.validate()) return
      this.isLoading = true
      try {
        const response = await this.getCSV(this.query)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        link.setAttribute('download', `${this.query.specie}.csv`)
        document.body.appendChild(link)
        link.click()
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `CSV Successfully Exported` }
        })
        this.onReset()
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        this.onReset()
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    },

    onReset() {
      this.query = this.createFreshQueryObject()
      this.$refs.form.resetValidation()
      this.isLoading = false
    },

    createFreshQueryObject() {
      return {
        specie: 'Sapele',
        startDate: new Date('2020-01-01').toISOString(),
        endDate: new Date().toISOString(),
        organizationId: ORGANIZATION_ID()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
